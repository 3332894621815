.backdrop {
  background: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(4px);
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  touch-action: none;

  &.visible {
    display: block;
  }
}
