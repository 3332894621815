@import 'src/comonStyles/variables.scss';

.home {
  display: flex;
  flex-flow: column;
  height: 100%;
  flex: 1 1 auto;
}

:global .uppy-DashboardTab-btn {
  height: 75px !important;
}
