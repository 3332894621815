.promotePlusPopup {
  font-family: Gelato Sans;
  font-style: normal;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.promotePlusHeader {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
}

.promotePlusText {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
}

.promotePlusTextSubheader {
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #34495E;
  margin-top: 10px;
}

.addPlusButton {
  width: 320px;
  height: 40px;
  border-radius: 5px;
  margin-top: 10px;
}

.continueToOpButton {
  margin-top: 10px;
  width: 320px;
  height: 40px;
}

.promotePlusLearnMore {
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #17A9ED;
  padding-top: 5px;
}

.promoteOpplusImage {
  height: 266px;
  width: 100%;
}

.promoteOpplusImage img {
  width: 100%;
  height: auto;
}
