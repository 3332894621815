@import 'src/comonStyles/variables.scss';

.centerSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  flex-direction: column;
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .attributesAndPrice {
    display: block;
    text-align: center;
    padding: 0 5px;
  }
  .priceInline {
    display: inline-block;
    margin-left: 5px;
    font-size: 13px !important;
  }
}
